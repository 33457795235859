<template>
  <div class="finances">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova categoria</span>
    </button>
    <b-table
      :data="allFinancialCategories"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/financial-categories/store/service'

export default {
  name: 'FinancialCategories',
  data() {
    return {
      model: { name: null },
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('financialCategories', ['allFinancialCategories']),
  },
  methods: {
    ...mapActions('financialCategories', ['getAllFinancialCategories']),
    async init() {
      await this.getAllFinancialCategories()
      console.log(this.allFinancialCategories)
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async save() {
      const financialCategory = {
        ...this.model,
      }

      try {
        const save = financialCategory.id
          ? await service.updateFinancialCategory(financialCategory)
          : await service.saveFinancialCategory(financialCategory)

        await this.$success('Categoria')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a categoria?')

        if (result.isConfirmed) {
          await service.deleteFinancialCategory(id)

          await this.$delete('Categoria')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
